import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './App.css';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';

function Home() {
  const [apiMessage, setApiMessage] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/api/message');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.text();
        setApiMessage(data);
      } catch (error) {
        console.error('Error fetching API data:', error);
        setApiMessage('Failed to load message from API');
      }
    }
    fetchData();
  }, []);

  return (
    <div className="container">
      <h1>MailMerge for WhatsApp</h1>
      <h2>About Our Business</h2>
      <p>MailMerge for WhatsApp is an innovative Excel add-in that allows businesses and individuals to send personalized WhatsApp messages to multiple recipients using data from their spreadsheets. Our tool streamlines communication processes, enhancing efficiency and personalization in bulk messaging.</p>
      <h2>Our Product</h2>
      <p>Our Excel add-in integrates seamlessly with Microsoft Excel, providing a user-friendly interface for creating and sending personalized WhatsApp messages. It's designed for businesses of all sizes, from small enterprises to large corporations, who need to communicate effectively with their customers or clients.</p>
      <h2>API Message</h2>
      <p>{apiMessage}</p>
      <h2>Contact Information</h2>
      <p>Email: product@1990quebec.com</p>
      <div className="policy-links">
        <h3>Important Policies</h3>
        <p>Please review our policies to understand how we operate and protect your rights:</p>
        <ul>
          <li><Link to="/terms-of-service">Terms of Service</Link></li>
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
        </ul>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>

        <footer className="footer">
          <p>&copy; 2023 MailMerge for WhatsApp. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
