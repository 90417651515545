import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>

      <h2>1. Information We Collect</h2>
      <p>We collect information you provide directly to us, such as your name, email address, and any other information you choose to provide.</p>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to comply with legal obligations.</p>

      <h2>3. Information Sharing and Disclosure</h2>
      <p>We do not share your personal information with third parties except as described in this policy or with your consent.</p>

      <h2>4. Data Security</h2>
      <p>We implement appropriate technical and organizational measures to protect the security of your personal information.</p>

      <h2>5. Your Rights</h2>
      <p>You have the right to access, correct, or delete your personal information. You may also have additional rights under applicable law.</p>

      <h2>6. Changes to This Policy</h2>
      <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>

      <h2>7. Contact Us</h2>
      <p>If you have any questions about this privacy policy, please contact us at product@1990quebec.com.</p>
    </div>
  );
}

export default PrivacyPolicy;
