import React from 'react';

function TermsOfService() {
  return (
    <div className="container">
      <h1>Terms of Service</h1>

      <h2>1. Acceptance of Terms</h2>
      <p>By using MailMerge for WhatsApp, you agree to these Terms of Service. If you do not agree, please do not use our service.</p>

      <h2>2. Description of Service</h2>
      <p>MailMerge for WhatsApp is an Excel add-in that allows users to send personalized WhatsApp messages using spreadsheet data.</p>

      <h2>3. User Obligations</h2>
      <p>Users must comply with all applicable laws and regulations when using our service. Users are responsible for the content of their messages.</p>

      <h2>4. Intellectual Property</h2>
      <p>All content and functionality of MailMerge for WhatsApp is the exclusive property of our company and is protected by copyright laws.</p>

      <h2>5. Limitation of Liability</h2>
      <p>We are not liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of our service.</p>

      <h2>6. Modifications to Service</h2>
      <p>We reserve the right to modify or discontinue our service at any time, with or without notice.</p>

      <h2>7. Governing Law</h2>
      <p>These Terms of Service are governed by the laws of Government of Canada.</p>

      <h2>8. Contact Information</h2>
      <p>For any questions regarding these Terms of Service, please contact us at legal@1990quebec.com.</p>
    </div>
  );
}

export default TermsOfService;
